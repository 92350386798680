import React, { useState, useEffect } from "react"
import {ReactReader, ReactReaderStyle} from "react-reader"

const HOST: string = "https://girq.s3.eu-central-1.amazonaws.com";

type BookInterface = {
	author: string;
	language: string;
	title: string;
}

type IndexInterface = {
	[key: string]: BookInterface
}

const App = () => {
	const [path, setPath] = useState<string>("lusya_mehrabyan/am/kani_angam_nyin_chanaparhov");
	const [book, setBook] = useState<BookInterface | undefined>({author: "Լուսյա Մեհրաբյան", language: "am", title: "Քանի՞ անգամ՝ նույն ճանապարհով"});
	const [index, setIndex] = useState<IndexInterface>({});
	const [location, setLocation] = useState<string | number | undefined>(undefined);
	useEffect(
				() =>
				{
					console.log("Indexing...");
					fetch(HOST + "/index.json", { method: "GET" })
					.then(
						(response) =>
						{
							if (!response.ok) {
								console.error("Fault: HTTP error");
								return ;
							}
							response.json()
							.then(
									(body) =>
									{
										setIndex(body);
										if (!book && path in index) {
											setBook(index[path]);
										}
									},
									(error) =>
									{
										console.error("Fault: ", error);
									}
							)
						}
					);
				},
				[book, path, index]
	);
	
	const onLocationChanged = (epubcifi: string | number | undefined) =>
	{
		console.log(epubcifi);
		// @brief epubcfi(/6/6[titlepage]!/4/2/12[pgepubid00003]/3:0)
		setLocation(epubcifi);
	};

	const url: URL = new URL(window.location.href);
	const path_list: string[] = url.pathname.split('/', 3);
	if (path_list.length >= 3) {
		setPath(path[0] + "/" + path[1] + "/" + path[2]);
		setBook(undefined);
	}

	return (
			<div style={{ height: "100vh" }}>
				<ReactReader 
							title = {!book ? "..." : book.author + " — " + book.title}
							showToc = {true}
							location = {location}
							locationChanged = {onLocationChanged}
							url = {`${HOST}/${path}.epub`}
				/>
			</div>
	)
}

export default App